
<template>
    <div class="mx-auto" style="max-width: 1000px; position: relative">
      <Card class="card-custom card-block">
        <template #title>
          <div class="column-wrapper align-center">
            <div class="column-three text-left"><back-button /></div>
              <h4 class="c-black flex-1 column-three">{{$t('segment_data')}}</h4>
              <div class="column-three"></div>
          </div>
        </template>
        <template #content>
          <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
                  <!-- multiple -->
                  <Card class="card-custom form-block">
                    <template #title>
                      </template>
                    <template #content>
                      <div class="space-y-1">
                    <!-- :class="{ 'mb-3' : selectedCurrencies.length > 3 }" -->
                  <ValidationProvider
              :name="$t('name')"
              rules="required"
              v-slot="{ errors, validate, validated }"
            > 
              <h4 class="c-text white-space title-input">{{ $t('name') }}<span class="c-red">*</span>:</h4>
              <div class="wrapper-input">
                  <InputText
              class="fw"
              :class="{ 'p-invalid' : errors.length > 0 }"
              :placeholder="$t('name')" 
              v-model="segment.name" 
              />
                <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
              </div>
          </ValidationProvider>
                  <ValidationProvider
              :name="$t('description')"
              rules="required"
              v-slot="{ errors, validate, validated }"
            > 
              <h4 class="c-text white-space title-input">{{ $t('description') }}<span class="c-red">*</span>:</h4>
              <div class="wrapper-input">
                  <InputText
              class="fw"
              :class="{ 'p-invalid' : errors.length > 0 }"
              :placeholder="$t('description')" 
              v-model="segment.description" 
              />
                <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
              </div>
          </ValidationProvider>
        <!-- <span> 
          <h4 class="c-text white-space title-input">
            {{ $t('active') }}: 
          </h4>
          <div class="d-flex flex-column">
            <InputSwitch class="p-inputswitch-success" v-model="segment.is_enabled" />
          </div>
        </span> -->
      </div>
      </template>
      </Card>
              <Button @click="handleSubmit(validate)" 
          :disabled="formLoading" 
          :loading="formLoading"
          :label="$t('create')"
                  icon="pi pi-save" class="p-button-sm p-button-success fw mt-2" />
          </ValidationObserver>
              
        </template>
      </Card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
name: 'CreateSegment',
computed: {
  ...mapGetters({
      currencies: 'getFilteredCurrencies',
  }),
},
watch: {
},
data() {
  return {
  loadingFlag: false,
  formLoading: false,
  selectedCurrencies: [
  ],
  segment: {
    name: '',
    description: '',
  },
  }
},
async mounted() {
},
methods: {
  handleSubmit(validate) {
      validate().then(valid => {
          if (valid) {
              this.createSegment();
          }
      })
  },
async createSegment() {
    this.formLoading = true;
  try {
      await this.$store.dispatch('segments/createSegment', this.segment);
      await this.$router.push('/segments');
      this. segment = {
        name: '',
        description: '',
        },
      this.$toast.add({ severity: 'success', summary: this.$t('segment_created'), life: 4000 });
        await this.dispatchSegments();
    } catch (error) {
      this.$toast.add({ severity: 'error', summary: this.$t('just_error'),
      detail: error,
      life: 4000 });
    } finally{
      this.formLoading = false;
    }
},
  async dispatchSegments() {
      await this.$store.dispatch('segments/awaitGetSegments');
  },
},
}
</script>

<style lang="scss" scoped>
.form-block{
//max-height: 214px;
//overflow: auto;
display: flex;
flex-direction: column;
span{
display: flex;
align-items: center;
}
.title-input {
flex: 0 0 200px;
display: flex;
//justify-content: flex-end;
@media (max-width:500px){
flex: 0 0 100px;
justify-content: flex-start;
align-items: center;
text-align: left;
}
}
.wrapper-input {
//flex: 0 0 200px;
// width: 100%;
flex: 1 1 auto;
@media (max-width:500px){
flex: 1 1 100%;
}
}

.v-input{
min-width: 170px;
max-width: max-content;
margin-right: 5px;
//&:not(:last-child) {
//}
margin-bottom: 6px;
&.error--text{
    margin-bottom: 0;
}
}
&_height {
flex-direction: column;
align-items: stretch;
}

&__header {
}

&__inputs {
display: flex;
flex-wrap: wrap;
.v-input{
    @media (minh:500px){
        max-width: 170px;
        
    }
}
}
}
.half {
display: flex;
@media (max-width:850px){
flex: 1 1 100%;
}    
@media (min-width:850px){
flex-direction: column;

}
.v-input{
@media (max-width:850px){
margin-right: 3px;
max-width: 50%;
min-width: 50%;
}

}
}
</style>